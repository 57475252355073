import { HttpClient } from '@angular/common/http';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { CrmDictionary } from 'common-module/core/types';
import { CrmDisplayService, CrmDisplayViewMode } from 'common-module/display';
import { get, isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { MenuItem, menuItems } from '~/config/menu.config';
import { Utils } from '~/shared/utils/utils';

import { UserApiService } from './user-api.service';
import { notHiddenStaffParams } from './user.filters';
import { UserModel } from './user.model';
import {
  UserPermission,
  UserPermissionKey,
  UserPermissions,
} from './user.permissions';

export const UseLocalDocutPermissionToken = new InjectionToken<boolean>(
  'UseLocalDocutPermissionToken'
);

@Injectable({ providedIn: 'root' })
export class UserPermissionsService {
  public permissions?: UserPermissions;

  public _roles?: UserModel['roles'];
  public _user?: string;

  private userService = inject(UserApiService);
  private http = inject(HttpClient);
  private displayService = inject(CrmDisplayService);
  private useLocalPermissions = inject(UseLocalDocutPermissionToken, {
    optional: true,
  });

  constructor() {
    this.userService.logout$.subscribe(() => this.invalidate());
  }

  get user() {
    return this._user;
  }

  public getPermissions(): Observable<UserPermissions> {
    if (this.permissions) {
      return of(this.permissions);
    }
    return this.userService.user$.pipe(
      tap((user) => {
        this._user = user._id;
        this._roles = user.roles;
      }),
      switchMap((user) => {
        if (this.useLocalPermissions) {
          return this.http.get<UserPermissions>(
            '/assets/static/permissions.json'
          );
        }
        return of(user?.permissions?.frontend ?? ({} as UserPermissions));
      }),
      map((_permissions) => {
        const resolvedPermissions: UserPermissions = {} as UserPermissions;

        Object.keys(_permissions).forEach((permission) => {
          const key = permission as keyof UserPermissions;
          resolvedPermissions[key] = {
            display: this._canDisplay(_permissions[key]),
            ...Utils.omit(_permissions[key], 'display'),
          };
        });
        this.permissions = resolvedPermissions;
        return resolvedPermissions;
      })
    );
  }

  public getMenuItems(): MenuItem[] {
    if (!this.permissions) {
      return [];
    }

    return menuItems.slice().filter(this._filterMenuItem(this.permissions));
  }

  canDisplay(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'display');
  }

  canDetail(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'detail');
  }

  canAdd(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'add');
  }

  canAdd$(key: UserPermissionKey): Observable<boolean> {
    return this.hasPermission$(key, 'add');
  }

  canEdit(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'edit');
  }

  canEdit$(key: UserPermissionKey): Observable<boolean> {
    return this.hasPermission$(key, 'edit');
  }

  canDelete(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'delete');
  }

  canCancel(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'cancel');
  }

  canExport(key: UserPermissionKey): boolean {
    return this.hasPermission(key, 'export');
  }

  hasPermission(key: UserPermissionKey, action: string): boolean {
    return get(this.permissions ?? {}, key)?.[action] ?? false;
  }

  hasPermission$(key: UserPermissionKey, action: string): Observable<boolean> {
    return this.getPermissions().pipe(
      map(() => this.hasPermission(key, action))
    );
  }

  public withoutOwnParams(
    permission: UserPermissionKey,
    property: string = '_id'
  ) {
    const params: CrmDictionary = {};

    if (!this.hasPermission(permission, 'own')) {
      params[`${property}[$nin][]`] = this.user;
    }

    return params;
  }

  public listStaffByPermission(
    permission: UserPermissionKey,
    params?: CrmDictionary
  ): Observable<UserModel[]> {
    if (this.hasPermission(permission, 'staff')) {
      return this.userService.listAllStaff({
        ...this.withoutOwnParams(permission),
        ...params,
        ...notHiddenStaffParams,
      });
    } else if (this.hasPermission(permission, 'own')) {
      return this.userService.user$.pipe(map((user) => [user]));
    } else {
      return of([]);
    }
  }

  private invalidate(): void {
    delete this.permissions;
    delete this._user;
    delete this._roles;
  }

  private _canDisplay(permission: UserPermission): boolean {
    if (!permission) {
      return false;
    }
    if (!isNil(permission.display)) {
      return permission.display;
    }
    return Object.values(permission).some((v) => this._canDisplay(v));
  }

  private _filterMenuItem = (permissions: UserPermissions) => {
    return (m: MenuItem): boolean => {
      if (m.children) {
        m.children = m.children.filter(this._filterMenuItem(permissions));
        return m.children.length > 0;
      } else {
        if (
          !m.desktopAllowed &&
          this.displayService.isMinimalView(CrmDisplayViewMode.LG)
        ) {
          return false;
        }

        if (
          !m.tabletAllowed &&
          this.displayService.isView(CrmDisplayViewMode.MD)
        ) {
          return false;
        }

        if (!m.permissionKey) {
          return false;
        }

        const permission = get(permissions, m.permissionKey, {
          display: false,
        });

        if ('display' in permission) {
          return permission.display;
        }

        return false;
      }
    };
  };
}
